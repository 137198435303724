main {
  height: 100vh;
}

.no-background-image {
  background-image: none !important;
}

/* Styles of the right-hand menu + pages with that menu */
.with-sidebar {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*margin-top: 50px;*/
  overflow: hidden;

  .views-container {
    flex: 1 0 0;
    /*-webkit-backface-visibility: hidden;*/

    &.sidebar-is-closed {

      @media(min-width: 768.01px) {
        margin-left: -157px;
        transform: translate(157px);
      }
    }
  }

  >div {
    transition: all 0.5s;
  }

  .sidebar {
    background: #F1F1F1;
    height: calc(100vh - 50px);
    transition: all 0.5s;
    width: 217px;

    @media (max-width: 768px) {
      width: 60px;
    }

    ul {
      
      &>li:first-child>a {
        vertical-align: middle;
      }

      >solid-route {

        &[active]>li {
          background-color: var(--color-heading);
          color: white;
        }
        &:hover:not([active])>li {
          background: #E4E4E4;
        }
      }
    }

    /* Styles use with JS to open/close the sidebar */
    &.jsRightMenu {
      @media (min-width: 768,01px) {
        display: block;
      }

      @media (max-width: 768px) {
        position: fixed;
        top: 99px;
        right: 0;
        height: auto;
        z-index: 2999;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
      }

      solid-link[active] {
        color: white;
        background: var(--color-heading);

        >li {
          border-bottom: 1px solid var(--color-heading);
        }
      }

      solid-link:not([active]):hover {
        background: #e4e4e4;
      }
    }

    &.jsRightMenu:not([open]) {
      @media (min-width: 768.01px) {
        transform: translate(157px);
      }
    }

    &.jsRightMenu[open] {
      display: block;
      bottom: 0;
      right: 0;
    }
  }

  .jsMobileRightMenuButton {
    float: right;
  }
}

/* Add scrollbar to the left menu and to the content */
.scrollbar-nav,
.scrollbar-content {
  overflow-y: auto;
  height: calc(100vh - 50px); /* 50px == header's height */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.scrollbar-content {

  @media (max-width: 768px) {
    position: relative;
  }
}

/* Custom scrollbar of the right-menu */
/* Works on Firefox*/
.scrollbar-nav {
  scrollbar-width: thin;
  scrollbar-color:#E4E9F1 var(--color-heading);
}

/* Works on Chrome, Edge, and Safari */
.scrollbar-nav {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-heading);          /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color:#E4E9F1;                /* color of the scroll thumb */
    border-radius: 6px;                        /* roundness of the scroll thumb */
    border: 3px solid var(--color-heading);    /* creates padding around scroll thumb */
  }
}

/* Custom scrollbar of the content */
/* Works on Firefox */
.scrollbar-content {
  scrollbar-width: thin;
  scrollbar-color:#E4E9F1 white;            /* scroll thumb and track */
}

/* Works on Chrome, Edge, and Safari */
.scrollbar-content {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: white;                        /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color:#E4E9F1;                 /* color of the scroll thumb */
    border-radius: 6px;                         /* roundness of the scroll thumb */
    border: 3px solid white;                  /* creates padding around scroll thumb */
  }
}
/* End scrollbar*/


solid-display-value-markdown {
  
  p {
    margin: 0;
  }
}

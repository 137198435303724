.chat-view {
  height: calc(100vh - 50px - 78px);

  @media (max-width: 768px) {
    height: calc(100vh - 50px - 68px);
  }
}

#messages {

  @media (max-width: 768px) {

    height: calc(100vh - 50px);

    .messages-header {
      display: flex;
    }

    solid-set-default[name="segment2"] {
      font-size: 14px;
      line-height: 16px;
      width: 75%;
    }

    orbit-user-avatar {
      height: 31px;
      width: 31px;
    }

    solid-display-value[name="name"] {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 210px;
    }

    solid-display-value[name="hyphen"] {
        display: none;
    }

    solid-link {
      vertical-align: super;
    }
  }
}

nav#main__menu {

  @media (min-width: 768.01px) {
    height: 100vh;
  }

  &.searching {
    background-image: url('../images/magnify-white.svg');
    background-color: var(--color-heading);
    background-repeat: no-repeat;
    background-position-x: right -20px;
    background-position-y: 150px;
    background-size: 80%;

    @media (max-width: 768px) {
      background-size: 60%;
    }
  }

  .transparent-background {
    background-color: transparent;

    &.active[active] {
      background-color: var(--color-third);
    }

    &.hover:hover:not([active]) {
      background-color: #202B3C;
    }

    &.reverse {
      background-color: var(--color-third);
    }
  }

  .unread {
    font-weight: bolder;
  }

  .badge {
    float: right;
    margin-right: 2px;
  }

  .icon {
    padding: 3px;
  }

  .menu-search {
    padding: 0;

    form::before,
    solid-form-placeholder-text::before {
      display: none;
    }

    form {
      display: flex;

      solid-form-placeholder-text {
        display: contents;
      }
    }

    input {
      background: transparent;
      color: #D0D4DA;
      margin-top: 0;
      padding: 12px 13px;
      height: 50px;

      /* https://css-tricks.com/almanac/selectors/p/placeholder/ */
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #D0D4DA;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #D0D4DA;
      }

      &:focus {
        /*background: #202B3C;*/
        color: white;
      }
    }

    div>button {
      background: transparent;
      border: none;
      color: #D0D4DA;
      margin-top: 0 !important;
      height: 100%;

      @media (max-width: 768px) {
        width: 20%;
      }

      &:hover::before {
        color: white;
      }
    }
  }

  .menu-header {

    /* Chevron animation */
    .transform-up {
      transform: rotateX(180deg);
    }

    .badge {
      padding-top: 2px;
      margin-right: 5px;
    }

    @media (min-width: 768.01px) {

      &:not(:hover) .menu-options,
      &:not(:hover) .menu-admin {
        display: none;
      }
    }

    .menu-options {
      float: right;
    }

    .menu-admin {
      position: absolute;
      z-index: 3016;
      left: 15%;
      top: 36px;
      text-align: end;
      width: 80%;

      @media (max-width: 768px) {
        width: 100%;
        left: 0;
        height: 9vh;
        top: 0;
        text-align: center;
        position: fixed;
        top: 50px;
        height: calc(100% - 50px);
      }
    }
  }

  .sub-menu {

    /* Styles for the small icon */
    .sub-menu-icon {
      margin-left: 3px;
      opacity: 0.8;
      width: 12px;

      >div {
        line-height: 19px;
      }
    }

    .sub-menu-name {
      margin-right: 15px;
      margin-left: 3px;
      vertical-align: middle;
      width: calc(75% - 15px);
    }
  }

  /* Ellipsis */
  .ellipsis {

    orbit-menu-fix-url-circle,
    orbit-menu-fix-url-project {
      display: block;
    }

    .ellipsis-content,
    .ellipsis-content>div {
      display: block;
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }

  .empty {
    display: flex;
    flex-wrap: wrap;
    padding-left: 13px;
    width: 87%;
  }

  .divider {
    height: 1px;
    background-color: #D7DBE0;
    opacity: 0.2;
  }


  /* Header's general counter appear or disappear on click (JS animation) */
  .menu-header>div:not(.transform-up)~.badge {
    display: none;
  }

  .menu-header>div.transform-up~.badge {
    display: block;
  }

  /* End */

  /* The little cross icon on contacts' name appears or disapear + hover + styles */
  .sub-menu__contacts>div>solid-display {
    height: 28px;
    display: block;

    orbit-menu-contact-removed {
      display: none;
    }

    &:hover {

      orbit-menu-fix-url-contact {
        display: inline-block;
        width: 100%;
      }

      orbit-counter {
        padding-right: 25px;
      }

      solid-set-default[name="message"] {
        margin-right: 5px;
        width: 75%;
      }

      orbit-menu-contact-removed {
        width: 38px;
        height: 27.75px;
        text-align: right;
        position: relative;
        top: -28px;
        display: block;
        left: calc(100% - 44px);
        padding-top: 5px;

        solid-delete > button {
          visibility: hidden;

          &::before {
            font-size: 18px;
            color: white;
            visibility: visible;
            position: relative;
            bottom: 1px;
            margin-left: 6px;
          }
        }
      }
    }
  }

  /* End */

  /* Modal to keep/remove a contact */
  orbit-menu-contact-removed dialog {
    background: white !important;
    border: none;
    padding: 60px 30px;
    text-align: center;

    @media (max-width: 768px) {
      width: 100vw;
    }

    p {
      margin-bottom: 30px;
      font-size: 16px;
    }

    div,
    p {
      background: white !important;
    }

    &::before,
    p::before,
    div::before,
    button::before {
      display: none;
    }
  }

  /* End */

  &.jsLeftMenu {
    display: inline-block;

    @media(max-width: 768px) {
      display: none;
    }
  }

  &.jsLeftMenu[open] {
    display: none;

    @media (max-width: 768px) {
      display: block;
      bottom: 0;
      box-shadow: 0 2px 8px 0 rgba(65, 69, 73, 0.5);
      width: 100%;
      position: fixed;
      right: 0;
      top: 50px;
      z-index: 3000;
    }
  }
}
#admin-circles,
#admin-circles-leave,
.communities-directory {
  .masonry > div:nth-child(1) {
    /* change from directory (nth-child(2))*/
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;

    .circle-subtitle-custom {
      height: 32px;
    }
  }

  .segment.hover:hover {
    box-shadow: 0 0 16px 0 rgba(46, 63, 88, 0.34);
    bottom: 2px;
    cursor: pointer;
  }

  orbit-admin-circle-name:hover {
    text-decoration: underline;
  }

  orbit-admin-community-logo {
    height: 76px;
    width: 100%;
    display: block;

    div {
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .tab.active {
    font-weight: bold;
    border-bottom: 5px solid var(--color-third);
    padding-bottom: 10px;
  }

  .communities-profile-picture {
    > div:not(:empty) {
      height: 200px;

      div:first-child {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .picture-empty {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .tabs {
      & > li {
        color: #3c3f57;
        font-size: 13px;
        letter-spacing: 0.18px;
        line-height: 17px;

        & > .tab {
          border-radius: 5px;
          box-shadow: 0 0 6px 0 rgba(46, 63, 88, 0.17);

          &.active {
            color: var(--color-primary);
            border: 1px solid var(--color-primary);
            box-shadow: none;
          }
        }
      }
    }

    orbit-communities-profile-logo {
      height: 100px;
    }

    .communities-profile-picture {
      > div:not(:empty) {
        height: 180px;
      }
    }
  }
}
